import '../styles/global.scss';
import '../styles/pages/dataroom.scss';

import {HeadFC} from "gatsby";

import React, {useEffect, useState} from 'react';


import {
    BigLogoImage,
    LoadingGif,
} from "../images";
import HeaderDataRoom from '../components/HeaderDataRoom';
import DataRoomDash from '../components/DataRoomDash';


type Props = {
    id: string,
}

const DataroomPage = ({id}: Props) => {

    const [isLoading, setLoading] = useState(true);

    const onPageLoad = () => {
        setLoading(false);
    }

    useEffect(() => {

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);


    return (
        <>
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="dataRoom">
                <HeaderDataRoom />

                <DataRoomDash />
            </div>
            
        </>
    )
}

export default DataroomPage;

export const Head: HeadFC = () => (
    <>
        <title>Solutions - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
