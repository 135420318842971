import './styles.scss';
import React, {memo, useLayoutEffect, useRef, useState} from 'react';

import { 
    Chart as ChartJS, 
    ArcElement, 
    Tooltip, 
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    ScriptableContext } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import CountUp from 'react-countup';
import { gsap, Power1 } from "gsap";

ChartJS.register(ArcElement, Tooltip);

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Tooltip,
);

import {
    traction1,
    traction2,
    traction3,
    traction4,
    questionIcon,
    ceoInsta,
    ceoLinked,
    ceoTwitter,
    ceoGmail
} from "../../images";

const DataRoomDash = () => {

    const rootEl = useRef(null);
    const timeLine = useRef();

    const [chartData, setChartData] = useState({
        labels: ['United State', 'United Kingdom', 'Australia', 'Canada'],
        datasets: [
            {
                label: '% of users',
                data: [70, 14, 8, 8],
                backgroundColor: [
                    '#FFC592',
                    '#FF8066',
                    '#00C9A7',
                    '#B181FF',
                ],
                borderWidth: 0,
                weight: 4,
                
            },
        ]
    });


    const options = {
        responsive: true,
        aspectRatio: 3,
        scales: {
            y: {
                border: {
                    color: '#1B1B1B',
                },
                ticks: {
                    stepSize: 5,
                    font: {
                        size: 10,
                    },
                    callback: function(value:any, index:any, ticks:any) {
                        return '$' + value + 'k';
                    }
                }
            },
            x: {
                border: {
                    color: '#1B1B1B',
                },
                ticks: {
                    font: {
                        size: 10,
                    },
                }
            }
        }
    };

    const [labels, setLabels] = useState([
        '', 
        'February', 
        '',
        '',
        '',
        '',
        'March',
        '',
        '',
        '',
        'April',
    ])

    const [lineData, setLineData] = useState({
        labels,
        datasets: [
            {
                fill: true,
                label: 'MRR',
                data: [0, 5, 5.7, 6.2, 7.4, 9, 10, 9.8, 10.3, 13, 20],
                borderColor: '#FFC592',
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, "rgba(147, 97, 54, 0.6)");
                    gradient.addColorStop(1, "rgba(255, 151, 129, 0)");
                    return gradient;
                },
                pointBackgroundColor: '#fff',
                pointBorderColor: '#FFC592',
                pointBorderWidth: 2,
                borderWidth: 2,
            },
        ],
    });

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            // timeLine.current && timeLine.current.progress(0).kill();
            // timeLine.current = gsap.timeline()
            
            setTimeout(() => {
                gsap.fromTo(".anime-stats", {
                    opacity: "0"
                }, {
                    opacity: "1",
                    duration: 0.41,
                    ease: Power1.easeIn,
                });
                gsap.fromTo(".anime-stats", {
                    scale: "2",
                    y: "50%"
                }, {
                    scale: "1",
                    y: "0%",
                    duration: 1.50,
                    ease: Power1.easeIn,
                });
            }, 250); 
            
            setTimeout(() => {
                gsap.fromTo(".anime-right-top", {
                    scale: "2",
                    x: "250%",
                    y: "-100%"
                }, {
                    scale: "1",
                    x: "0%",
                    y: "0%",
                    duration: 1.25,
                    ease: Power1.easeIn,
                })
                gsap.fromTo(".anime-right-top", {
                    opacity: "0"
                }, {
                    opacity: "1",
                    duration: 0.41,
                    ease: Power1.easeIn,
                });

                gsap.fromTo(".anime-right-bottom", {
                    scale: "2",
                    x: "250%",
                    y: "100%"
                }, {
                    scale: "1",
                    x: "0%",
                    y: "0%",
                    duration: 1.25,
                    ease: Power1.easeIn,
                })
                gsap.fromTo(".anime-right-bottom", {
                    opacity: "0"
                }, {
                    opacity: "1",
                    duration: 0.41,
                    ease: Power1.easeIn,
                });

                gsap.fromTo(".anime-bottom", {
                    scale: "2",
                    x: "0%",
                    y: "250%"
                }, {
                    scale: "1",
                    x: "0%",
                    y: "0%",
                    duration: 1.25,
                    ease: Power1.easeIn,
                })
                gsap.fromTo(".anime-bottom", {
                    opacity: "0"
                }, {
                    opacity: "1",
                    duration: 0.41,
                    ease: Power1.easeIn,
                });

                gsap.fromTo(".anime-left-bottom", {
                    scale: "2",
                    x: "-250%",
                    y: "100%"
                }, {
                    scale: "1",
                    x: "0%",
                    y: "0%",
                    duration: 1.25,
                    ease: Power1.easeIn,
                })
                gsap.fromTo(".anime-left-bottom", {
                    opacity: "0"
                }, {
                    opacity: "1",
                    duration: 0.41,
                    ease: Power1.easeIn,
                });
            }, 500)
            
        }, rootEl);
    }, []);

    return (
        <section className="dataRoomDash" ref={rootEl}>
            <div className="dataRoomContainer">
                <div className="dataRoomDashWrap">
                    <div className="dataRoomDashRow">
                        <div className="dataRoomDashBigcol anime-stats">
                            <div className="dataRoomDashHeading">
                                <span className="dataRoomDashTitle">Traction</span>
                                <div className="dataRoomDashTabs">
                                    <span className="dataRoomDashTab active">Finances</span>
                                    <span className="dataRoomDashTab">Marketing</span>
                                    <span className="dataRoomDashTab">Retention</span>
                                </div>
                            </div>

                            <div className="dataRoomDashStats">
                                <div className="dataRoomDashStat">
                                    <div className="dataRoomDashStatRound gold"></div>
                                    <img src={traction1} className="dataRoomDashStatIcon" />
                                    <div className="dataRoomDashStatInside">
                                        <span className="dataRoomDashStatValue">
                                            $<CountUp end={2500} duration={4} />
                                        </span>
                                        <p className="dataRoomDashStatText">
                                            <span className="gold">Monthly</span>
                                            Recurring Revenue
                                        </p>
                                    </div>
                                </div>
                                <div className="dataRoomDashStat">
                                    <div className="dataRoomDashStatRound purple"></div>
                                    <img src={traction2} className="dataRoomDashStatIcon" />
                                    <div className="dataRoomDashStatInside">
                                        <span className="dataRoomDashStatValue">
                                            <CountUp end={150} duration={4} />
                                        </span>
                                        <p className="dataRoomDashStatText">
                                            <span className="purple">Paying users</span>
                                            In real time
                                        </p>
                                    </div>
                                </div>
                                <div className="dataRoomDashStat">
                                    <div className="dataRoomDashStatRound green"></div>
                                    <img src={traction3} className="dataRoomDashStatIcon" />
                                    <div className="dataRoomDashStatInside">
                                        <span className="dataRoomDashStatValue">
                                            $<CountUp end={30} duration={4} />
                                        </span>
                                        <p className="dataRoomDashStatText">
                                            <span className="green">Customer</span>
                                            Acquisition Cost
                                        </p>
                                    </div>
                                </div>
                                <div className="dataRoomDashStat">
                                    <div className="dataRoomDashStatRound blue"></div>
                                    <img src={traction4} className="dataRoomDashStatIcon" />
                                    <div className="dataRoomDashStatInside">
                                        <span className="dataRoomDashStatValue">
                                            $<CountUp end={200} duration={4} />
                                        </span>
                                        <p className="dataRoomDashStatText">
                                            <span className="blue">Projected</span>
                                            Lifetime value
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dataRoomDashSmallcol flex anime-right-top">
                            <div className="dataRoomFunds">
                                <div className="dataRoomFundsHead">
                                    <span>Confirmed funding</span>
                                    <p>Pre Seed Round</p>
                                </div>
                                <div className="dataRoomFundsProgress">
                                    <div className="dataRoomFundsInfo">
                                        <span>$100 000</span>
                                        <span>$500 000</span>
                                    </div>
                                    <div className="dataRoomFundsProgressBar">
                                        <div className="dataRoomFundsProgressLine" ></div>
                                    </div>
                                </div>
                                <a href="" className="dataRoomFundsMore">Lear more</a>
                            </div>
                            <a href="" className="dataRoomAsk">
                                <span className="dataRoomAskTitle">Ask us a question?</span>
                                <p className="dataRoomAskText">If you have a question</p>
                                <div className="dataRoomAskIcon">
                                    <img src={questionIcon} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="dataRoomDashRow">
                        <div className="dataRoomDashSmallcol2 anime-left-bottom">
                            <span className="dataRoomChartTitle">Users</span>
                            <div className="dataRoomCircleChartBox">
                                <div className="dataRoomCircleChart">
                                    <span className="dataRoomCircleChartNumber">4.222</span>
                                    <Doughnut 
                                        data={chartData}
                                        options={{
                                            cutout: '80%'
                                        }} 
                                        />
                                </div>
                                <div className="dataRoomCircleChartLabels">
                                    <span className="dataRoomCircleChartLabel color1">United State</span>
                                    <span className="dataRoomCircleChartLabel color2">United Kingdom</span>
                                    <span className="dataRoomCircleChartLabel color3">Australia</span>
                                    <span className="dataRoomCircleChartLabel color4">Canada</span>
                                </div>
                            </div>
                        </div>

                        <div className="dataRoomDashMediumcol anime-bottom">
                            <span className="dataRoomChartTitle">MRR Growth</span>
                            <div className="dataRoomLineChart">
                                <Line data={lineData} options={options} />
                            </div>
                        </div>

                        <div className="dataRoomDashSmallcol bg pa24 anime-right-bottom">
                            <div className="dataRoomDashCEO">
                                <div className="dataRoomDashCEOInfo">
                                    <span className="dataRoomDashCEOTitle">Andrew Filatov</span>
                                    <p className="dataRoomDashCEOPosition">Founder and CEO</p>
                                    <p className="dataRoomDashCEOText">Our mission to make life better through the education, formation habits and skills</p>
                                    <div className="dataRoomDashCEOBottom">
                                        <div className="dataRoomDashCEOSocbox">
                                            <a href="https://www.instagram.com/mr.aeer_/"><img src={ceoInsta} alt="" /></a>
                                            <a href="https://www.linkedin.com/in/aeer/"><img src={ceoLinked} alt="" /></a>
                                            <a href="https://twitter.com/mr_aeer"><img src={ceoTwitter} alt="" /></a>
                                            <a href="mailto:ceo@aeer-platform.com"><img src={ceoGmail} alt="" /></a>
                                        </div>
                                        <button className="dataRoomDashCEOBtn">Lets get in touch</button>
                                    </div>
                                </div>
                                <div className="dataRoomDashCEOImage"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    )
}

export default memo(DataRoomDash);