import './styles.scss';
import React, {memo} from 'react';

import {
    BigLogoImage,
    dataroomIcon4,
    dataroomIcon3,
    dataroomIcon2,
    dataroomIcon1,
    share
} from "../../images";

const HeaderDataRoom = () => {

    return (
        <header className="dataRoomHeader">
            <div className="dataRoomContainer dataRoomHeaderContainer">
                <a href="" className="dataRoomLogo">
                    <img src={BigLogoImage} alt="" />
                </a>
                <nav className="dataRoomNav">
                    <ul className="dataRoomNavCol">
                        <li className="dataRoomNavItem active">
                            <img src={dataroomIcon4} alt="" />
                            <span>Dashboard</span>
                        </li>
                        <li className="dataRoomNavItem">
                            <img src={dataroomIcon3} alt="" />
                            <span>About AEER</span>
                        </li>
                        <li className="dataRoomNavItem">
                            <img src={dataroomIcon2} alt="" />
                            <span>Road map</span>
                        </li>
                        <li className="dataRoomNavItem">
                            <img src={dataroomIcon1} alt="" />
                            <span>Documents</span>
                        </li>
                    </ul>
                </nav>
                <a href="" className="dataRoomHeaderMore">
                    <img src={share} alt="" />
                </a>
            </div>
        </header>
    )
}

export default memo(HeaderDataRoom);